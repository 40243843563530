<template>
  <token-validation
    has-fill-record
    :title="$t('reservation.data_validation_access')"
    :description="$t('reservation.person_creation_description')"
    :back-btn-text="$t('reservation.back_button')"
    :auth="auth"
    :contacts="contacts"
    :is-loading="isLoading"
    :is-timer-on="isTimerOn"
    :waiting-minutes="waitingMinutes"
    :validation-type="validationType"
    @back="$router.push({ name: 'home' })"
    @change="changeAuth({ pin: $event })"
    @resend="onResendCode"
    @finish="checkPinValidation"
  />
</template>

<script>
import TokenValidation from "./components/TokenValidation.vue"
import { useTokenValidation } from "./useTokenValidation"

export default {
  components: { TokenValidation },
  setup() {
    return useTokenValidation()
  },
}
</script>
